<template>
    <div>
        <header>
            <span class="back" @click="headback">
                <i class="el-icon-arrow-left" style="font-size:14px;"></i>
                返回
                <i class="line">|</i>
            </span>
            <span class="titile">创建文章</span>
        </header>
        <div class="ditchbox">
            <div class="management">
                <div class="redact_wrap">
                    <el-form :model="redactForm" ref="redactForm" :rules="redactRules" label-width="160px">
                        <el-form-item label="标题" prop="title">
                            <div class="redact_title">
                                <el-input v-model="redactForm.title" show-word-limit></el-input>
                            </div>
                        </el-form-item>
                        <el-form-item label="分类" prop="article_cat_id">
                            <div class="redact_title">
                                <el-cascader filterable clearable v-model="redactForm.article_cat_id" ref="cascader"
                                    :options="optionparent"
                                    :props="{ value: 'id', label: 'name', children: 'children', checkStrictly: true, emitPath: false, leaf: 'hasChildren' }">
                                </el-cascader>
                            </div>
                        </el-form-item>
                        <el-form-item label="作者" prop="author">
                            <div class="redact_title">
                                <el-input v-model="redactForm.author"></el-input>
                            </div>
                        </el-form-item>
                        <el-form-item label="排序" prop="sort">
                            <div class="redact_title">
                                <el-input type="number" :min="0" :max="10" v-model="redactForm.sort"></el-input>
                            </div>
                        </el-form-item>
                        <!-- <el-form-item label="图片" prop="img">
                            <div class="d-flex align-items-center">
                                <div class="skeletonize" @click="uploadImgs('img')">
                                    <i class="el-icon-plus" v-show="!redactForm.img"></i>
                                    <img class="addresstximage" :src="redactForm.img" v-show="!!redactForm.img" />
                                </div>
                            </div>
                            <div class="skeletonize_toast">建议尺寸:206x154</div>
                        </el-form-item> -->
                        <!-- <el-form-item label="是否显示" prop="is_show">
                            <div>
                                <el-radio-group v-model="redactForm.is_show">
                                    <el-radio :label="0">隐藏</el-radio>
                                    <el-radio :label="1">显示</el-radio>
                                </el-radio-group>
                            </div>
                        </el-form-item> -->
                        <!-- <el-form-item label="是否置顶" prop="is_top">
                            <div>
                                <el-radio-group v-model="redactForm.is_top">
                                    <el-radio :label="0">否</el-radio>
                                    <el-radio :label="1">是</el-radio>
                                </el-radio-group>
                            </div>
                        </el-form-item> -->
                        <el-form-item label="内容" prop="content">
                            <div>
                                <div id="editor—wrapper">
                                    <div id="editor-container">
                                        <!-- 编辑器 -->
                                        <Editor v-model="redactForm.content" placeholder="文章内容" height="350"
                                            @change="articledetail" @uploadImgs="uploadImgs" />
                                    </div>
                                </div>
                            </div>
                        </el-form-item>
                        <!-- <el-form-item label="推荐商品" prop="redactForm">
                            <div>
                                <div>
                                    <el-radio-group v-model="redactForm.is_recommend">
                                        <el-radio :label="0">隐藏</el-radio>
                                        <el-radio :label="1">显示</el-radio>
                                    </el-radio-group>
                                </div>
                                <template v-if="redactForm.is_recommend == 1">
                                    <div class="recommend_foot">
                                        <div class="goodsbtn" @click="shoptype = true">添加商品</div>
                                    </div>
                                    <div class="recommend_table" v-if="selectshop && selectshop.length">
                                        <el-table :data="selectshop">
                                            <el-table-column label="商品" prop="img" min-width="60">
                                                <template slot-scope="scope">
                                                    <div class="shopImg">
                                                        <img :src="scope.row.img" alt="">
                                                    </div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column min-width="229">
                                                <template slot-scope="scope">
                                                    <div class="shopDetail">
                                                        <div>{{ scope.row.title }}</div>
                                                        <div>积分{{ scope.row.price }}</div>
                                                    </div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column min-width="229" label="总库存" prop="inventory"></el-table-column>
                                            <el-table-column min-width="140" label="操作">
                                                <template slot-scope="scope">
                                                    <div class="caozuo">
                                                        <a href="javascript:void(0);" @click="delshop(scope)">删除</a>
                                                    </div>
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </div>
                                </template>
                            </div>
                        </el-form-item> -->
                    </el-form>
                </div>

            </div>
        </div>
        <diy-footer-button v-if="hasPerm(['article.update', 'article.store'])">
            <el-button type="primary" @click="handleSubmit">保存</el-button>
        </diy-footer-button>
        <!-- 图片选择器 -->
        <pictureSelect ref="imglist" :multipled="is_multiple" @change="imgbrand($event)"></pictureSelect>
    </div>
</template>
<script>
import { Message } from 'element-ui';
import common from '../../common/common';

export default {
    data() {
        return {
            redactForm: {
                title: '', //标题
                article_cat_id: '', //分类
                author: '', //作者
                sort: 0,//排序
                is_show: 0, //是否显示
                is_top: 0, //是否置顶
                content: '', //富文本编辑器内容
                is_recommend: 0, //推荐商品
                img: "",//图片
            },
            optionparent: [],//分类cascader
            redactRules: {
                title: [{
                    required: true,
                    message: '请输入文章标题',
                    trigger: 'blur'
                }],
                article_cat_id: [{
                    required: true,
                    message: '请选择文章分类',
                    trigger: 'change'
                }],
                author: [{
                    required: true,
                    message: '请填写作者',
                    trigger: 'blur'
                }],
                sort: [{
                    required: true,
                    message: "请输入排序值",
                    trigger: 'blur'
                }, {
                    validator: (rules, value, callback) => {
                        if (value < 0) {
                            callback(new Error("排序值不可为负数!"));
                        }
                        callback();
                    },
                    trigger: 'blur'
                }],
                content: [{
                    required: true,
                    message: '请填写文章内容',
                    trigger: 'blur'
                }],
            },
            is_multiple: 1,//0 单选   1多选
        }
    },
    watch: {
        ['$route.query.id']: {
            handler(newVal, oldVal) {
                if (newVal) {
                    this.$get(`${this.$apis.contentArticleApi}/${newVal}`).then(res => {
                        if (res.code == 200) {
                            this.$nextTick(() => {
                                this.$set(this, 'redactForm', { ...res.data })
                            })
                        } else {
                            Message.error(res.message)
                        }
                    })
                }
            },
            deep: true,
            immediate: true
        }
    },
    created() {
        this.getClassArticleList();
    },
    methods: {
        // 返回
        headback() {
            this.$router.back();
        },
        // 获取分类列表
        async getClassArticleList() {
            const { data: { list } } = await this.$get(this.$apis.contentArticleClassApi);
            const result = common.changeCascaderSource(list, true);
            this.$set(this, "optionparent", result);
        },
        // 文章文本编辑器change事件
        articledetail(html) { },
        // 图片选择器
        imgbrand(val) {
            if (this.uploadImgs.type) {
                this.redactForm.img = val[0].file_url;
            } else {
                // 富文本支持多选图
                val.forEach((item) => {
                    this.redactForm.content += `<p><img src="${item.file_url}" style="max-width:100%;"/></p>`
                })
            }

            this.$forceUpdate();
        },
        // 富文本编辑器图片选择 / 选择图片
        uploadImgs(type) {
            this.uploadImgs.type = type;
            this.$refs.imglist.photoVisible = true;
        },
        // 取消
        handleCancel() {
            this.$refs['redactForm']['resetFields']();
            this.$router.back();
        },
        // 保存
        handleSubmit() {
            this.$refs['redactForm']['validate'](async valid => {
                if (valid) {
                    const { code, message } = await this[this.$route.query.id ? '$put' : '$post'](`${this.$apis.contentArticleApi}/${this.$route.query.id || ''}`, this.redactForm);
                    Message[(() => code == 200 ? 'success' : 'error')()]({
                        message: `${this.$route.query.id ? '更新' : '添加'}文章${code == 200 ? '成功!' : message}`,
                        offset: 200
                    })
                    code == 200 && this.handleCancel();
                }
            })
        }
    },
}
</script>
<style scoped lang="less">
@import url("css/ditch.css");

.addresstximage {
    display: block;
    width: 80px;
    height: 80px;
    text-align: center;
    line-height: 80px;
    border: 1px dashed #ededed;
    box-sizing: border-box;
    font-size: 40px;
    color: #dad9d9;
    font-weight: 400;
    cursor: pointer;
    object-fit: cover;
}

/deep/.el-cascader {
    width: 100%;
}
</style>